<template>
  <window-content v-if="!loading && !errors && !success">
    <e-row class="w-lote">
      <e-col style="max-width: 600px">

        <e-row mr class="m-t">
          <e-col style="max-width: 300px">
            <erp-s-field
                view="lr"
                label="ID:"
                label-width="130px"
            >
              <erp-input readonly v-model="lance.id"/>
            </erp-s-field>
          </e-col>

          <e-col>
            <erp-s-field
                view="lr"
                label="IP de Origem:"
            >
              <erp-input readonly v-model="lance.ip"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row mr>
          <e-col style="max-width: 300px">
            <erp-s-field
                view="lr"
                label="Data:"
                label-width="130px"
            >
              <erp-input readonly v-model="lance.data"/>
            </erp-s-field>
          </e-col>

          <e-col>
            <erp-s-field
                view="lr"
                label="Valor Limite:"
            >
              <erp-input v-model="lance.valorLimite" v-money="money"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row mr>
          <e-col style="max-width: 300px">
            <erp-s-field
                view="lr"
                label="Parcelado:"
                label-width="130px"
            >
              <erp-select required :options="[{label: 'Sim',value: true},{label: 'Não',value: false}]"
                          v-model="lance.parcelado"/>
            </erp-s-field>
          </e-col>

          <e-col>
            <erp-s-field
                view="lr"
                label="Parcelas:"
            >
              <erp-input :disable="!lance.parcelado" v-model="lance.parcelas" type="number"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row mr>
          <e-col>
            <erp-s-field
                view="lr"
                label="Arrematante:"
                label-width="130px"
            >
              <arrematante-input v-model="lance.arrematante" :finded-data-obj="cache.arrematanteSelected"
                                 @updateSelected="(data) => cache.arrematanteSelected = data"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row mr>
          <e-col>
            <erp-s-field
                view="lr"
                label="Leilão:"
                label-width="130px"
            >
              <e-row class="font-12">
                {{ lance.lote.leilao.codigo }} #{{ lance.lote.leilao.id }}
              </e-row>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                view="lr"
                label="Lote"
            >
              <e-row class="font-12">
                {{ lance.lote.numero }} #{{ lance.lote.id }}
              </e-row>
            </erp-s-field>
          </e-col>
        </e-row>

        <!-- TODO: Aprovação comitente, log aprovação etc -->

      </e-col>
    </e-row>
    <window-footer class="flex content-between">
      <!--<div class="flex items-center justify-center font-11">
        <div class="m-l-xs">
          Ao salvar o leilão ele ficará com status Rascunho. Após validar as informações, altere o
          status para Em breve.
        </div>
      </div>-->
      <div class="inline-block window-actions content-between col-grow">
        <u-btn label="Modificar Lance Automático" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" class="pull-left"/>
        <u-btn label="Deletar Lance Automático" icon="times" color="negative" icon-type="fa" icon-style="light" flat no-caps
               @click="deletar" class="pull-left"/>
        <u-btn label="Fechar" icon="times" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>

      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140"/>
    </div>
    <h5 class="m-t">Lance Automático modificado com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)"/>
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else/>
</template>

<script>
/* eslint-disable */
import {
  WindowContent,
  // WindowHeader,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  // HelperInputBtn,
  // ErpBox,
  ErpLabel,
  ErpSelect,
  ErpCheckbox,
  ErpBox
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import WindowSuccess from '../../../layout/window/Success'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'
import {atualizaLance, findLanceAutomatico as find} from '../../../../domain/leiloes/services/index'
import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import ArrematanteInput from '../../../arrematante/helpers/input/ArrematanteInput'
import LanceRegistroStatus from '../include/lote/LanceRegistroStatus'

let mockLance = {
  id: null,
  data: null,
  parcelado: null,
  parcelas: null,
  indice: null,
  valorLimite: null
}

export default {
  name: 'LanceWindow',
  props: ['id'],
  directives: {money: VMoney},
  data() {
    return {
      money: REAL_BRL,
      tabActive: 'detalhes',
      loading: true,
      errors: null,
      success: false,
      status: null,
      lance: JSON.parse(JSON.stringify(mockLance)),
      mock: {},
      hasAlteracao: false,
      cache: {
        arrematanteSelected: null,
      }
    }
  },
  mounted() {
    this.loading = true
    this.id && find(this.id)
        .then(response => {
          let data = response.data

          data.valorLimite = 'R$ ' + this.$options.filters.moeda(data.valorLimite || 0)
          data.data = this.$options.filters.formatDate(data.data.date, 'dd/MM/yyyy HH:mm:ss')

          if (data.arrematante && data.arrematante.id) {
            this.cache.arrematanteSelected = data.arrematante
            data.arrematante = data.arrematante.id
          }

          this.lance = data
          this.$nextTick(() => {
            this.loading = false
          })
        })
        .catch((error) => {
          this.$uloc.dialog({
            title: 'Falha ao carregar!',
            color: 'negative',
            message: `Não conseguimos carregar o lance automático, entre em contato com o administrador.`
          })
          this.loading = false
          this.errors = error.data
          console.error(error, error.data)
        })
  },
  computed: {},
  watch: {},
  methods: {
    changeTab({tab}) {
      this.tabActive = tab
    },
    save() {
      console.log('Save')
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.lance))

      data.valorLimite = convertRealToMoney(data.valorLimite)

      // data.extra = JSON.stringify(data.extra)

      this.status = 'Processando modificação no lance'
      atualizaLance(this.lance.id, data)
          .then(response => {
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                // this.$refs.timer.start()
                // this.load()
                window.setTimeout(() => {
                  this.loading = false
                  this.success = false
                }, 3000)
                this.$uloc.window.emit(this.$root.wid, 'update', this.props)
              })
            }
            cbSuccess()
          })
          .catch(error => {
            console.log(error)
            this.loading = false
            this.alertApiError(error)
          })
    },
    deletar() {
      alert('Funcionalidade não ativada.')
    }
  },
  components: {
    LanceRegistroStatus,
    ArrematanteInput,
    ECol,
    ERow,
    DefaultAsyncSelect,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    WindowBody,
    // WindowHeader,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    ErpLabel,
    // UEditor,
    // URadio
    ErpSelect,
    ErpCheckbox,
    ErpBox
  }
}
</script>
